<template>
	<div>
		<!-- 后台报表 -->
		<div class="queryBox mb20">
			<el-button size="mini" type="primary" icon="el-icon-plu" @click="addAccount('new')">{{$t('main.accountMenu.add')}}</el-button>
		</div>
		
		<!-- <div class="queryBox mb20 center">
			<div class="ml60">
				<template>
					<el-checkbox-group size="medium" v-model="TypeCheckList" @change="handleCheckedTypeChange">
						<el-checkbox-button v-for="item,index in typeList" :label="item.id" :key="index">{{item.name}}</el-checkbox-button>
					</el-checkbox-group>
				</template>
			</div>
		</div> -->
		<div>
			
				<el-table :max-height="height" v-loading="loading" stripe :data="tableData" border :indent="40" row-key="id" size="mini" default-expand-all :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
					<el-table-column fixed label="账号" width="150px" align="center" show-overflow-tooltip>
						<template slot-scope="scope">
							<span v-if="scope.row.name != '总计'" class="treeData" type="primary" @click="showInfo(scope.row)">{{scope.row.username}}</span>
							<!-- <el-link v-if="scope.row.name != '总计'" class="treeData" type="primary" @click="showInfo(scope.row)">{{scope.row.username}}</el-link> -->
							<span v-if="scope.row.name == '总计'">{{scope.row.name}}</span>
						</template>
					</el-table-column>
					<el-table-column fixed :label="$t('main.accountMenu.agentName')" align="center">
						<template slot-scope="scope">
							<span v-if="scope.row.name != '总计'" :class="scope.row.state === 0?'csuccess':''">{{scope.row.name}}</span>
						</template>
					</el-table-column>
					<!-- <el-table-column :label="$t('main.accountMenu.totalAmount')"  width="150px" align="center">
						<template slot-scope="scope">
							<span v-if="scope.row.name != '总计'" class="moneyFontSize">{{scope.row.CNY_money | tofixed}}</span>
							<span v-if="scope.row.name == '总计'" class="">{{scope.row.total_money | tofixed}}</span>
						</template>
					</el-table-column> -->
					<el-table-column label="余额"  width="160px" align="center">
						<template slot-scope="scope">
							<div v-if="scope.row.name != '总计'" class="flexX flexBetween">
								<span>{{scope.row.money | tofixed_two}}</span>
								<span><el-link type="success" @click="addAccount('unFreeze',scope.row)">调额</el-link></span>
							
							</div>
							<span v-if="scope.row.name == '总计'" class="" >{{scope.row.total_money | tofixed}}</span>
							
						</template>
					</el-table-column>
					
					<el-table-column :label="$t('main.accountMenu.state')" align="center">
						<template slot-scope="scope">
							<span v-if="scope.row.name != '总计'" :class="{cerror:scope.row.status!=0}">{{scope.row.status===0?$t('main.accountMenu.enable'):$t('main.accountMenu.disable')}}</span>
							
						</template>
					</el-table-column>
					<el-table-column :label="$t('main.accountMenu.remarks')" align="center" width="100px">
						<template slot-scope="scope">
							<div v-if="scope.row.name != '总计'">
								{{scope.row.note}}
							</div>
						</template>
					</el-table-column>
					<el-table-column fixed='right' :label="$t('main.accountMenu.function')"  width="200px">
						<template slot-scope="scope">
							<div v-if="scope.row.name != '总计'">
								<el-link type="primary" @click="addAccount('edt',scope.row)" class="mr20">{{$t('main.accountMenu.edit')}}</el-link>
								<el-link type="primary" @click="openDrawer(scope.row)" class="mr20">费率管理</el-link>
								
								<!-- <el-link type="primary" @click="addAccount('freeze',scope.row)" class="mr10">扣除余额</el-link>
								<el-link type="primary" @click="addAccount('unFreeze',scope.row)" class="mr10">增加余额</el-link> -->
								
								<!-- <el-button type="text" @click="openDrawer(scope.row)">费率设置</el-button> -->
								<!-- <el-link type="primary" @click="addAccount('fee',scope.row)" class="mr20">{{$t('main.accountMenu.commissionCollection')}}</el-link>
								<el-link type="primary" @click="addAccount('reFee',scope.row)" class="mr20">{{$t('main.accountMenu.commissionPayment')}}</el-link>
								<el-link type="primary" @click="addAccount('offlineFee',scope.row)" class="mr20">{{$t('main.accountMenu.offlineDepositHandlingFee')}}</el-link> -->
								<el-link type="primary" @click="resPassword(scope.row)" class="mr20">{{$t('main.accountMenu.resetAgentPassword')}}</el-link>
								<el-link type="primary" @click="addAccount('new',scope.row)">{{$t('main.accountMenu.addSubordinateAgent')}}</el-link>
								
							</div>
						</template>
					</el-table-column>
					
			
				</el-table>
			
		</div>
		<!---------------------------------- 抽屉操作 START -------------------------------------->
		<div>
			<el-drawer
			  title=""
			  :show-close="true"
			  :visible.sync="dwdialog"
			  direction="rtl"
			  custom-class="demo-drawer"
			  ref="drawer"
			  :with-header="false"
			  size='50%'
			  :destroy-on-close="true"
			  >
			  <div  v-loading="dialogloading" class="demo-drawer__content">
				  <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
				    <el-menu-item index="fee">代收手续费</el-menu-item>
				    <el-menu-item index="reFee">代付手续费</el-menu-item>
				  </el-menu>
				  <!-- 代收费率 -->
				  <el-form v-if="activeIndex === 'fee'" :label-position="labelPosition" label-width="80px" class="mb20">
				  	<el-table v-loading="dwloading" :data="newFeeData" height="550px" stripe size="mini"  border  :span-method="objectSpanMethod" >
				  		<el-table-column prop="pay_type_name" :label="$t('main.orderMenu.collectionMethod')"  align="center" width="160px">
								<template slot-scope="scope">
										【{{scope.row.pay_type}}】{{scope.row.pay_type_name}} 
								</template>
						</el-table-column>
						
				  		<el-table-column label="费率(‰)" width="">
				  			<template slot-scope="scope">
				  				<div class="flexX flexStart">
									 <el-input class="mr10" style="width: 7.5rem;" size="small" placeholder="请输入费率,千分" v-model="scope.row.fee">
									    <template slot="append">‰</template>
									  </el-input>
				  					<div>通道：{{scope.row.min_fee | tofixed_two}}  </div>
				  				</div>
				  			</template>
				  		</el-table-column>
				  	</el-table>
				  
				  </el-form>
				  
				  <!-- 代付费率 -->
				  <el-form size="mini" v-if="activeIndex === 'reFee'" :label-position="labelPosition" label-width="80px" class="mb20">
				  	<el-table v-loading="dwloading" :data="agentRepayFeeData" height="550px" stripe size="mini"  border  :span-method="objectSpanMethod" >
				  		<el-table-column :label="$t('main.orderMenu.paymentMethod')" width="150px"  align="center">
							<template slot-scope="scope">
									【{{scope.row.pay_type}}】{{scope.row.pay_type_name}} 
							</template>
						</el-table-column>
				  		<el-table-column label="费率(‰)"  align="center">
				  			<template slot-scope="scope">
				  				<div class="flexX flexStart">
									 <el-input class="mr10" style="width: 7.5rem;" size="small" placeholder="请输入费率,千分" v-model="scope.row.fee">
									    <template slot="append">‰</template>
									  </el-input>
				  					<div>通道：{{scope.row.min_fee | tofixed_two}}  </div>
				  				</div>
				  			</template>
				  		</el-table-column>
				  		<el-table-column label="单笔(元)"   align="center">
				  			<template slot-scope="scope">
				  				<div class="flexX flexStart">
				  					<el-input class="mr10" style="width: 7.5rem;" size="mini" v-model="scope.row.fee_num" placeholder="请输入内容"></el-input>
				  					<span class="ml20">通道：{{scope.row.min_fee_num | tofixed_two}} </span>
				  				</div>
				  			</template>
				  		</el-table-column>
				  	</el-table>
				  	<!-- <el-pagination :hide-on-single-page="true" @size-change="feehandleSizeChange" @current-change="feehandleCurrentChange"
				  	 :current-page="feepage" :page-sizes="feepageSizesArr" :page-size="feepageSize" layout="total, sizes, prev, pager, next, jumper"
				  	 :total="feetotal"></el-pagination> -->
				  
				  </el-form>
				  
				  <div class="textCenter">
				  	<el-button type="primary" @click="onSubmit">{{submitText}}</el-button>
				  </div>
				  
			  </div>
			</el-drawer>
		</div>
		
		
		
		<!--------------------------------- 抽屉操作 END -------------------------------------->
		
		<div class="flexX pageBox mt20">
			<el-pagination :hide-on-single-page="true" @size-change="handleSizeChange" @current-change="handleCurrentChange"
			 :current-page="page" :page-sizes="pageSizesArr" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
			 :total="total"></el-pagination>
		</div>
		<el-dialog class="dialog" width="30%" top="50px" :title="title" :visible.sync="dialogVisible" @close="close" :close-on-click-modal="false" center>
			<div class="formBox" v-loading="dialogloading">
				<div>
					<div v-if="titleName && (type === 'fee' || type === 'offlineFee')" class="mb20">代理账号：{{titleName}}</div>
					<div v-if="titleName &&type === 'sigle_fee'" class="mb20 flexX flexcenter">
						<div class="flex1 flexX flexcenter">
							<span class="mr20">单笔手续费</span>
							<el-input v-model="agentFee.fee_num" placeholder="单笔手续费"  class="flex1"></el-input>
						</div>
						<div class="flex1 flexX flexcenter flexTextCenter">
							<el-link type="primary" class="mr20" @click="paywayTypeFeeList(agentFee,'sigle')"> 最小值:{{agentFee.min_fee_num | tofixed}} </el-link>
							<el-link type="primary" @click="openInnerDialog(agentFee,'sigle')"> 最大值:{{agentFee.max_fee_num | tofixed}}</el-link>
						</div>
					</div>
					<el-form  size="mini" v-if="type != 'fee'" :label-position="labelPosition" label-width="80px" :model="form">
																	
						<el-form-item label="登录账号" v-if="type === 'edt'">
							<el-input :disabled="type != 'new'" v-model="form.agent" placeholder="请输登录账号"></el-input>
						</el-form-item>
						<el-form-item label="上级代理" v-if="type == 'new' && id">
							<span>{{pusername}} / {{pname}} </span>
						</el-form-item>	
						<el-form-item label="名称" v-if="type === 'edt' || type === 'new'">
							<el-input v-model="form.name" placeholder="请输入名称"></el-input>
						</el-form-item>
					
						<el-form-item label="商户类型" v-if="type === 'edt' || type === 'new'">
							  <el-select v-model="form.typeValue" placeholder="请选择">
							    <el-option
							      v-for="item in typeList"
							      :key="item.id"
							      :label="item.name"
							      :value="item.id">
							    </el-option>
							  </el-select>
						</el-form-item>
						<el-form-item label="登录账号" v-if="type === 'new'">
							<el-input :disabled="type != 'new'" v-model="form.agent" placeholder="请输入登录账号"></el-input>
						</el-form-item>
						<el-form-item label="登录密码" v-if="type === 'new'">
							<el-input type="password" autocomplete="new-password" v-model="password" placeholder="请输入登录密码"></el-input>
						</el-form-item>
						<el-form-item label="确认登录密码" v-if="type === 'new'">
							<el-input type="password" autocomplete="new-password" v-model="passwordSure" placeholder="请再次输入登录密码"></el-input>
						</el-form-item>
						<el-form-item :label="$t('main.accountMenu.state')" v-if="type === 'edt' || type === 'new'">
							<el-switch v-model="state" class="mr20"></el-switch>
							<span>{{state?$t('main.accountMenu.enable'):$t('main.accountMenu.disable')}}</span>
						</el-form-item>
						<el-form-item :label="$t('main.accountMenu.remarks')" v-if="type === 'edt' || type === 'new'">
							<el-input v-model="form.note" placeholder="请输入备注"></el-input>
						</el-form-item>
						<!-- 修改密码 -->
						<el-form-item label="新密码" v-if="type === 'password'">
							<el-input type="password" v-model="password" placeholder="请输入新密码"></el-input>
						</el-form-item>
						<el-form-item label="确认密码" v-if="type === 'password'">
							<el-input type="password" v-model="passwordSure" placeholder="请再次输入密码"></el-input>
						</el-form-item>
					</el-form>
					
					
					<el-form :model="amountForm" size="mini" v-if="type === 'freeze' || type ==='unFreeze'" :label-position="labelPosition" class="mb20"  label-width="50px">
						<div class="flexY flexAround mb20">
							<el-input class="mb20" placeholder="请输入内容" v-model="this.item.username">
								<template slot="prepend">代理账号:</template>
							</el-input>
							<el-input placeholder="请输入内容" v-model="this.item.name">
								<template slot="prepend">代理名称:</template>
							</el-input>
						</div>
						<div class="mb20 fs18 ml10">当前余额: <span class="tabletdColor fs20">￥{{this.item.money}}</span></div>
						 <el-form-item label="调额:" prop="amount" :rules="{ required: true, message: '调额不能为空'}">
						    <el-input v-model="amountForm.amount">
								<template slot="prepend">￥</template>
							</el-input>
							<span>如需扣除余额，则输入负数。例如：-300</span>
						  </el-form-item>
						 <el-form-item label="谷歌:" prop="googleCode" :rules="[{ required: true, message: '谷歌不能为空'},]">
						    <el-input v-model="amountForm.googleCode">
							</el-input>
						  </el-form-item>
						 <el-form-item label="备注:" prop="amountNote" >
						    <el-input
						      type="textarea"
						      :rows="3"
						      placeholder="请输入备注"
						      v-model="amountForm.amountNote">
						    </el-input>
						  </el-form-item>
						
					</el-form>
					
					
				</div>
				<div class="textCenter">
					<el-button size="mini" type="primary" @click="onSubmit">{{submitText}}</el-button>
				</div>
			</div>
			<el-dialog :title="innerDialogTitle" :visible.sync="innerVisible" append-to-body @close="closeInner" :close-on-click-modal="false" center>
				<div v-loading="dialogInnerVisible">
					<el-table :data="agentTraderFeeList" stripe size="mini" border v-if="!minFeeList && !haschild">
						<el-table-column prop="business_no" label="商户号" width="220px" align="center"></el-table-column>
						<el-table-column prop="business_name" label="商户名称" align="center"></el-table-column>
						<el-table-column label="手续费" align="center">							
							<template slot-scope="scope">
								<span v-if="feeType == 'sigle'">{{scope.row.fee_num | tofixed}}</span>
								<span v-if="feeType != 'sigle'">{{scope.row.fee}}</span>
							</template>							
						</el-table-column>
					</el-table>
					
					<el-table :data="agentTraderFeeList" stripe size="mini" border v-if="(minFeeList && childAgent) ||(!minFeeList && haschild)">
						<el-table-column prop="username" label="代理账号" width="220px" align="center"></el-table-column>
						<el-table-column prop="name" :label="$t('main.accountMenu.agentName')" align="center"></el-table-column>
						<el-table-column label="手续费" align="center">
							<template slot-scope="scope">
								<span v-if="feeType == 'sigle'">{{scope.row.fee_num | tofixed}}</span>
								<span v-if="feeType != 'sigle'">{{scope.row.fee}}</span>
							</template>
						</el-table-column>
					</el-table>
					<el-table :data="agentTraderFeeList" stripe size="mini" border v-if="minFeeList && !childAgent">
						<el-table-column prop="name" label="通道名称" width="220px" align="center"></el-table-column>
						<el-table-column label="手续费" align="center">
							<template slot-scope="scope">
								<span v-if="feeType == 'sigle'">{{scope.row.fee_num | tofixed}}</span>
								<span v-if="feeType != 'sigle'">{{scope.row.fee}}</span>
							</template>
						</el-table-column>
					</el-table>
					<div class="textCenter mt20">
						<el-button type="primary" @click="closeInner">关 闭</el-button>
					</div>
				</div>

			</el-dialog>
		</el-dialog>
	</div>
</template>

<script>
	import {
		isEmpty,
		isPositiveNumber,
		isMoney,
		isIncludeHanzi
	} from '@/util/validate'
	import {
		Message
	} from 'element-ui'
	export default {
		data() {
			return {
				loading: false,
				dialogloading: false,
				dialogVisible: false,
				innerVisible: false, //内层弹框
				innerDialogTitle: '', //内层弹框
				dialogInnerVisible: false, //内层弹框
				date: [],
				input: '',
				tableData: [],
				dataTotal: {},
				pageSizesArr: [20, 50, 100, 200],
				pageSize: 20,
				total: 0,
				page: 1,
				feepageSizesArr: [20, 50, 100, 200],
				feepageSize: 20,
				feetotal: 0,
				feepage: 1,
				//新增
				labelPosition: 'right',
				form: {
					name: '',
					agent: '',
					password: '',
					note: '',
					state: 0,
					typeValue: '',
				},
				state: true,
				password: '',
				passwordSure: '', //确认密码
				type: '', //new  新增   edt修改
				id: '',
				title: '新增代理',
				titleName: '',
				submitText: this.$t('main.accountMenu.add'),
				feeData: [],
				agentTraderFeeList: [], //手续费商家列表
				height:0,
				minFeeList:false,
				pusername:'', //新增下级代理
				pname:'',
				item:{},
				childAgent:false,
				haschild:false,
				agentFee:{},
				feeType:'',
				newFeeData:[],
				agentRepayFeeData:[],
				dwloading:false,
				dwdialog:false,
				activeIndex:'fee',
				dwitem:{},
				dwloading:false,
				// 代理&商户分类
				typeList:{},
				TypeCheckList:[],
				// 增加扣除余额
				freezeCurrency: '',
				currencyFreeZeList:{},
				// 调额
				amountForm: {
					amount:'',
					amountNote: '',
					googleCode:'',
				},
			}
		},
		created() {
			this.agentList()
			this.getTypeList()
			this.$nextTick(() => {
				let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
				let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
				let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
				let documentHeight = document.documentElement.clientHeight
				this.height = documentHeight - topNav - 20 - pageBox - 20 - 100
			})
		},
		filters: {
			tofixed: function(value) {
				if (value || value == 0) {
					let val =Math.floor(((value * 1)*10000).toPrecision(12))
					return ((val/10000).toFixed(4) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
				}
			},
			tofixed_two: function(value) {
				if (value || value == 0) {
					let val =Math.floor(((value * 1)*10000).toPrecision(12))
					return ((val/10000).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
				}
			}
		},
		methods: {
			getCurrency(){
				let data = {
					id: this.id,
				}
				this.$api.agentMoney(data).then(res => {
					this.currencyFreeZeList = res.data
					console.log(this.currencyFreeZeList);
				})
			},
			handleCheckedTypeChange(value){
				this.agentList()
			},
			getTypeList(item) {
				let data = {
					trader_id: this.id,
					business_no: this.business_no,
				}
				this.$api.typeList(data).then(res => {
					if (res.status == 1) {
						this.typeList = res.data
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			agentList() {
				// this.loading = true
				this.$api.agentList({
					page: this.page,
					size: this.pageSize,
					type: this.TypeCheckList.join(',')
				}).then(res => {
					this.loading = false
					if (res.status === 1) {
						this.tableData = res.data
						this.total = res.data.total
						// this.dataTotal = {
						// 	all_total_money: res.data.all_total_money,
						// 	all_total_money_uncertain: res.data.all_total_money_uncertain,
						// 	current_total_money: res.data.current_total_money,
						// 	current_total_money_uncertain: res.data.current_total_money_uncertain,
						// }
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
					// console.log(res)
				}).catch(error => {
					this.loading = false
				})
			},
			setDate(day) {
				let data = this.date?this.date[0]:this.$util.timestampToTime(new Date().getTime(), true)
				let newDate = this.$util.getLastDate(data, day);
				this.date = [newDate, newDate]
			},
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.pageSize = val
				this.page = 1
				this.agentList()
			},
			handleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.page = val
				this.agentList()
			},
			addAccount(type, item) {
				this.dialogVisible = true
				this.type = type
				if (item) {
					this.item = item;
					this.id = item.id;
					this.form.name = item.name;
					this.form.agent = item.username;
					this.form.typeValue = Number(item.type) == 0?'':Number(item.type);
					this.form.password = '';
					this.form.note = item.note;
					this.form.state = item.status;
					this.state = this.form.state === 0 ? true : false;
					this.password = '';
				}
				if (this.type === 'new') {
					this.form.name ='';
					this.form.agent = '';					
					this.form.note = '';
					this.form.state = 0;
					this.title = '新增代理'
					if(item){
						this.title = this.$t('main.accountMenu.addSubordinateAgent') // '新增下级代理'
						this.pusername = item.username
						this.pname = item.name
					}
					
					this.submitText = this.$t('main.accountMenu.add')

				} else if (this.type === 'edt') {
					this.title = '编辑代理'
					this.submitText = '修 改'
				} else if (this.type === 'password') {
					this.title = '修改密码'
					this.submitText = '修 改'
				} else if (this.type === 'fee') {
					this.dialogVisible = false
					this.title = this.$t('main.accountMenu.commissionCollection') //'充值手续费'
					this.submitText = '确 定'
					this.titleName = item.username
					this.agentFeeList()
				}
				else if(this.type === 'reFee'){
					this.dialogVisible = false
					this.title = this.$t('main.accountMenu.commissionPayment') //'代付手续费'
					this.submitText = '确 定'
					this.agentreFeeList()

				}
				 else if (this.type === 'offlineFee') {
					this.dialogVisible = false
					this.title = this.$t('main.accountMenu.offlineDepositHandlingFee') // '线下入金手续费'
					this.submitText = '确 定'
					this.titleName = item.username
					this.agentOfflineFeeList()
				} else if (this.type === 'freeze') {
					this.title = '扣除余额'
					this.titleName = item.business_name + ' / ' + item.business_no
					this.submitText = '确 定'
					// this.getCurrency()
				} else if (this.type === 'unFreeze') {
					this.title = '增加余额'
					this.titleName = item.business_name + ' / ' + item.business_no
					this.submitText = '确 定'
					// this.getCurrency()
				}

				// this.$router.push('/agentAccountAdd')
			},
			feehandleSizeChange(val) {
				this.feepageSize = val
				this.feepage = 1
				this.agentFeeList()
			},
			feehandleCurrentChange(val) {
				this.feepage = val
				this.agentFeeList()
			},
			agentreFeeList(){
				let data = {
					agent_id: this.id,
					page: this.feepage,
					size: this.feepageSize
				}
				this.dwloading = true
				this.$api.agentRepayFeeList(data).then(res => {
				
					this.dwloading = false
					this.feeData = res.data.data
					this.feetotal = res.data.total
					this.agentRepayFeeData = [];
					this.agentRepayFeeData = res.data.data
					
					this.spanArr= []
					this.pos= ''
					this.spanArr2= []
					this.pos2= ''
					this.getSpanArr(this.agentRepayFeeData)
				})

			},
			//手续费列表查询
			agentFeeList() {
				let data = {
					agent_id: this.id,
					page: this.feepage,
					size: this.feepageSize
				}
				// this.dialogloading = true
				this.dwloading = true
				this.$api.agentFeeList(data).then(res => {
					this.dialogloading = false
					this.dwloading = false
					if (res.status === 1) {
						this.feeData = res.data.data
						this.feetotal = res.data.total
						this.newFeeData = [];
						this.newFeeData = res.data.data;
						
						this.spanArr= []
						this.pos= ''
						this.spanArr2= []
						this.pos2= ''
						this.getSpanArr(this.newFeeData)
						
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.dialogloading = false
					this.dwloading = false
				})
			},
			//手续费列表查询
			agentOfflineFeeList() {
				let data = {
					agent_id: this.id,
				}
				// this.dialogloading = true
				this.dwloading = true
				this.$api.agentOfflineFeeList(data).then(res => {
					this.dialogloading = false
					this.dwloading = false
					if (res.status === 1) {
						this.feeData = res.data.data
						this.feetotal = res.data.total
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.dialogloading = false
					this.dwloading = false
				})
			},
			close() {
				this.form.name = '';
				this.form.agent = '';
				this.form.password = '';
				this.form.note = '';
				this.form.typeValue = '';
				this.form.state = 0;
				this.state = true;
				this.password = '';
				this.passwordSure = '';
				this.dialogVisible = false;
				this.dwloading = false;
				this.id = '';
				this.fee = '';
				this.feeData = [];
				this.pusername = ''
				this.pname = ''
				this.item = {}
				this.amountForm.amount = ''
				this.amountForm.amountNote = ''
				this.amountForm.googleCode = ''
				this.freezeCurrency = ''
			},
			//内层弹框关闭
			closeInner() {
				this.minFeeList = false; 
				this.innerVisible = false
				this.innerDialogTitle = ''
				this.agentTraderFeeList = []
				this.childAgent = false
				this.haschild = false
				this.feeType = ""
			},
			//新增
			onSubmit() {
				//账号状态  0正常  1冻结
				this.form.state = this.state ? '0' : '1'
				this.form.password = this.$md5(this.password)
				
				if (this.type == 'new') {
					if (isEmpty(this.form.name, '请输入代理名称')) {
						return
					}
					if (isEmpty(this.form.agent, '请输入代理账号')) {
						return
					}
					if (isIncludeHanzi(this.form.agent, '代理账号不能包含汉字')) {
						return
					}
					if (isEmpty(this.password, '请输入代理密码')) {
						return
					}
					if (isEmpty(this.form.typeValue, '请选择代理类型')) {
						return
					}
					if (this.password != this.passwordSure) {
						this.$message({
							type: 'error',
							message: '两次输入密码不一致'
						})
						return
					}
					this.dialogloading = true
					this.form.pid = this.id
					
					let formData = {
						pid: this.form.pid,
						name: this.form.name,
						agent: this.form.agent,
						password: this.form.password,
						note: this.form.note,
						state: this.state ? '0' : '1',
						type: this.form.typeValue,
					}
					
					
					this.$api.addAgent(formData).then(res => {
						this.dialogloading = false
						if (res.status === 1) {
							this.$message({
								type: 'success',
								message: res.msg
							});
							this.close();
							this.page = 1;
							// setTimeout(()=>{
							this.agentList()
							// },200)

						} else {
							this.$message({
								type: 'error',
								message: res.msg
							});
						}
					}).catch(error => {
						this.dialogloading = false
					})
				} else if (this.type == 'edt') {
					if (isEmpty(this.form.name, '请输入代理名称')) {
						return
					}
					if (isEmpty(this.form.agent, '请输入代理账号')) {
						return
					}
					if (isEmpty(this.form.typeValue, '请选择代理类型')) {
						return
					}
					this.dialogloading = true
					let formData = {
						name: this.form.name,
						agent: this.form.agent,
						note: this.form.note,
						state: this.state ? '0' : '1',
						id: this.id,
						type: this.form.typeValue,
					}
					this.$api.editAgent(formData).then(res => {
						this.dialogloading = false
						if (res.status === 1) {
							this.$message({
								type: 'success',
								message: res.msg
							});
							this.close();
							// this.page = 1;
							// setTimeout(()=>{
							this.agentList()
							// },200)
							// this.agentList()
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							});
						}
						// console.log(res)
					}).catch(error => {
						this.dialogloading = false
					})
				} else if (this.type === 'fee') {
					let errorString = ''
					var pattern = /^(([1-9][0-9]*)|(([0]|[0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/
					for (let i = 0; i < this.newFeeData.length; i++) {
						if (this.newFeeData[i].fee === '' || (!pattern.test(this.newFeeData[i].fee))) {
							errorString = '手续费只能是数字，最多两位小数'
							break
						} else if (this.newFeeData[i].fee < this.newFeeData[i].min_fee ||  this.newFeeData[i].fee > this.newFeeData[i]. max_fee) {
							errorString = ' 商户手续费不能超过区间!'
							break
						}
						// if (this.newFeeData[i].fee_num === '' || (!pattern.test(this.newFeeData[i].fee_num)) ) {
						// 	errorString = '单笔费用只能是数字，最多两位小数'
						// 	break
						// } else if (this.newFeeData[i].fee_num < this.newFeeData[i].agent_fee_num) {
						// 	errorString = '单笔费用不能低于手续费!'
						// 	break
						// }
					}
					if (errorString != '') {
						this.$message({
							type: 'error',
							message: errorString
						})
						return
					}
					
					let feeData = this.newFeeData.map(item => {
						return {
							"pay_type": item.pay_type,
							"cur_id": item.cur_id,
							"fee": item.fee
						}
					})
					
					//设置手续费

					let data = {
						agent_id: this.id,
						data: JSON.stringify(feeData)
					}
					this.dialogloading = true
					// console.log('feedata', this.dialogloading)
					this.$api.setAgentFee(data).then(res => {
						this.dialogloading = false
						if (res.status === 1) {
							this.$message({
								type: 'success',
								message: this.$t('main.common.successConfirm')
							})
							this.agentFeeList()
							// this.close()
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.dialogloading = false
					})
				} 
				else if(this.type === 'reFee'){
					let errorString = ''
					var pattern = /^(([1-9][0-9]*)|(([0]|[0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/;
					for (let i = 0; i < this.agentRepayFeeData.length; i++) {
						if (this.agentRepayFeeData[i].fee === '' || (!pattern.test(this.agentRepayFeeData[i].fee))) {
							errorString = '费率只能是数字，最多两位小数'
							break
						} else if (this.agentRepayFeeData[i].fee < this.agentRepayFeeData[i].min_fee ||  this.agentRepayFeeData[i].fee > this.agentRepayFeeData[i].max_fee) {
							errorString = ' 商户费率不能超出区间!'
							break
						}
						if (this.agentRepayFeeData[i].fee_num === '' || (!pattern.test(this.agentRepayFeeData[i].fee_num)) ) {
							errorString = '单笔费用只能是数字，最多两位小数'
							break
						} else if (Number(this.agentRepayFeeData[i].fee_num) < Number(this.agentRepayFeeData[i].min_fee_num) || Number(this.agentRepayFeeData[i].fee_num) > Number(this.agentRepayFeeData[i].max_fee_num)) {
							
							console.log(this.agentRepayFeeData[i].fee_num)
							console.log(this.agentRepayFeeData[i].min_fee_num)
							console.log(this.agentRepayFeeData[i].max_fee_num)
							console.log(this.agentRepayFeeData[i].fee_num < this.agentRepayFeeData[i].min_fee_num)
							console.log(this.agentRepayFeeData[i].fee_num > this.agentRepayFeeData[i].max_fee_num)
							console.log('0.00' > '0')
							errorString = '单笔费用不能超出区间!'
							break
						}
					}
					if (errorString != '') {
						this.$message({
							type: 'error',
							message: errorString
						})
						return
					}
					let feeData = this.agentRepayFeeData.map(item => {
						return {
							"pay_type": item.pay_type,
							"fee": item.fee,
							'cur_id':item.cur_id,
							"fee_num": item.fee_num
						}
					})
					let data = {
						agent_id: this.id,
						data: JSON.stringify(feeData)
					}
					this.dialogloading = true
					this.$api.setAgentRepayFee(data).then(res => {
						this.dialogloading = false
						if (res.status === 1) {
							this.$message({
								type: 'success',
								message: res.msg
							})
							this.agentreFeeList()
							// this.close()
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.dialogloading = false
					})
				}
				else if (this.type === 'offlineFee') {
					let errorString = ''
					var pattern = /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/
					for (let i = 0; i < this.feeData.length; i++) {
						
						if (this.feeData[i].fee === '' || (!pattern.test(this.feeData[i].fee) && this.feeData[i].fee != 0 && this.feeData[i].fee != '0')) {
							errorString = '手续费只能是数字，最多两位小数'
							// console.log("+++++++++",this.feeData[i])
							break;
						} else if (this.feeData[i].max_fee === '') {
							if (this.feeData[i].fee < this.feeData[i].min_fee) {
								errorString = this.feeData[i].name + ': 手续费范围不能超出商户手续费范围!'
								break;
							}
						} else if (this.feeData[i].fee > this.feeData[i].max_fee || this.feeData[i].fee < this.feeData[i].min_fee) {
							errorString = this.feeData[i].name + ': 手续费范围不能超出商家手续费范围!'
							break;
						}
					}
					// console.log("=====",this.feeData)
					if (errorString != '') {
						this.$message({
							type: 'error',
							message: errorString
						})
						return
					}
					
					let feeData = this.feeData.map(item => {
						return {
							"currency": item.currency,
							"fee": item.fee
						}
					})
					
					//设置手续费

					let data = {
						agent_id: this.id,
						data: JSON.stringify(feeData)
					}
					this.dialogloading = true
					// console.log('feedata', this.dialogloading)
					this.$api.setAgentOfflineFee(data).then(res => {
						this.dialogloading = false
						if (res.status === 1) {
							this.$message({
								type: 'success',
								message: this.$t('main.common.successConfirm')
							})
							// this.close()
							this.agentOfflineFeeList()
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.dialogloading = false
					})
				} else if (this.type === 'freeze' || this.type === 'unFreeze') {
						
						let data = {
							id: this.id,
							agent: this.form.agent,
							amount: this.amountForm.amount,
							note:this.amountForm.amountNote,
							googleCode:this.amountForm.googleCode,
						}
						this.dialogloading = true
						this.$api.setAgentAmount(data).then(res => {
							this.dialogloading = false
							if (res.status === 1) {
								this.$message({
									type: 'success',
									message: res.msg
								})
								this.agentList()
								this.close()
							} else {
								this.$message({
									type: 'error',
									message: res.msg
								})
							}
						}).catch(error => {
							this.dialogloading = false
						})
				}
			},
			//重置密码
			resPassword(item) {
				this.$confirm(this.$t('main.common.confirm'), this.$t('main.common.prompt'), {
					confirmButtonText: this.$t('main.common.submit'),
					cancelButtonText: this.$t('main.common.cancel'),
					type: 'warning'
				}).then(() => {
					let data = {
						id: item.id
					}
					this.loading=true
					this.$api.setAgentPassword(data).then(res => {
						this.loading=false
						// console.log(res)
						if (res.status === 1) {
							this.$alert('新密码为:' + res.data, '重置密码成功', {
								confirmButtonText: '确定',
							});
						} else {
							this.$message({
								type: 'info',
								message: res.msg
							});
						}

					}).catch(error=>{
						this.loading=false
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消重置代理密码'
					});
				});

			},
			openInnerDialog(item,type) {
				this.feeType = type
				console.log('parentid',this.item.parentid)
				this.minFeeList = false;
				this.innerVisible = true
				// this.innerDialogTitle = item.name + ' 下商家费率列表'
				this.dialogInnerVisible = true
				if(this.item.children.length>0){
					if(type == "sigle"){
						this.innerDialogTitle =`${this.item.name}的下级代理单笔手续费`
					}else{
						this.innerDialogTitle =item.name + '下级代理费率列表'
					}
					// this.innerDialogTitle = item.name + ' 下级代理费率列表'
					this.haschild = true;
					// console.log(this.item.children.length,'this.item.children.length')
					let data = {}
					if(type == "sigle"){
						data = {
							pay_type:88,
							id:this.item.id
						}
					}else{
						data = {
							pay_type:item.pay_type,
							id:this.item.id
						}
					}
					this.$api.agentChildrenFeeList(data).then(res => {
						this.dialogInnerVisible = false
						if (res.status === 1) {
							if(res.data == ''){
								this.agentTraderFeeList = []
							}else{
								this.agentTraderFeeList = res.data
								let agentFeeArr = this.agentTraderFeeList.map(feeItem=>{
									return feeItem.fee
								})
								this.feeData.forEach(feeItem=>{
									if(feeItem.pay_type == item.pay_type){
										feeItem.max_fee = Math.min(...agentFeeArr)
									}
								})
							}
							
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.dialogInnerVisible = false
					})
				}else{
					if(type == "sigle"){
						this.innerDialogTitle =`${this.item.name}的商家单笔手续费`
						this.haschild = false
						let data = {
							agent_id: this.id,
						}
						this.$api.agentTraderFeeNumList(data).then(res => {
							this.dialogInnerVisible = false
							if (res.status === 1) {
								if(res.data == ''){
									this.agentTraderFeeList = []
								}else{
									this.agentTraderFeeList = res.data
								}
								
							} else {
								this.$message({
									type: 'error',
									message: res.msg
								})
							}
						}).catch(error => {
							this.dialogInnerVisible = false
						})
					}else{
						this.innerDialogTitle = item.name + ' 下商家费率列表'
						this.haschild = false
						let data = {
							agent_id: this.id,
							pay_type: item.pay_type
						}
						
						this.$api.agentTraderFeeList(data).then(res => {
							this.dialogInnerVisible = false
							if (res.status === 1) {
								if(res.data == ''){
									this.agentTraderFeeList = []
								}else{
									this.agentTraderFeeList = res.data
								}
								
							} else {
								this.$message({
									type: 'error',
									message: res.msg
								})
							}
						}).catch(error => {
							this.dialogInnerVisible = false
						})
					}
					
					
				}
					
				
			},
			paywayTypeFeeList(item,type) {
				this.feeType = type
				this.minFeeList = true;
				this.innerVisible = true
				if(type == "sigle"){
					this.innerDialogTitle =`${this.item.name}的单笔手续费`
				}else{
					this.innerDialogTitle =item.name + '的通道费率列表'
				}
				if(this.item.level>1){
					if(type == "sigle"){
						this.innerDialogTitle = '上级代理的单笔手续费'
					}else{
						this.innerDialogTitle = '上级代理的' + item.name + '费率'
					}
					this.childAgent = true
					let data ={}
					if(type == "sigle"){
						data = {
							pay_type:88,
							id:this.item.parentid
						}
					}else{
						data = {
							pay_type:item.pay_type,
							id:this.item.parentid
						}
					}
					this.$api.agentFeeInfo(data).then(res=>{						
							this.dialogInnerVisible = false
							if (res.status === 1) {
								this.agentTraderFeeList = [res.data]
								this.feeData.forEach(feeItem=>{
									if(feeItem.pay_type == item.pay_type){
										feeItem.min_fee = res.data.fee
									}
								})
							} else {
								this.$message({
									type: 'error',
									message: res.msg
								})
							}
					}).catch(error => {
						this.dialogInnerVisible = false
					})
				}else{
					let data = {}
					if(type == "sigle"){
						data = {
							pay_type:88,
							agent_id:this.id,
						}
					}else{
						data = {
							pay_type:item.pay_type,
							agent_id:this.id,
						}
					}
					
					this.dialogInnerVisible = true
					this.$api.paywayTypeFeeList(data).then(res => {
						this.dialogInnerVisible = false
						if (res.status === 1) {
							this.agentTraderFeeList = res.data
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.dialogInnerVisible = false
					})
				}
			},
			getSpanArr(data) {
				for (var i = 0; i < data.length; i++) {
					if (i === 0) {
						this.spanArr.push(1);
						this.pos = 0;
						
					} else {
						// 判断当前元素与上一个元素是否相同
						if (data[i].pay_type === data[i - 1].pay_type) {
							
							this.spanArr[this.pos] += 1;
							this.spanArr.push(0);
							
						} else {
							this.spanArr.push(1);
							this.pos = i;
						}
					}
					// console.log(this.spanArr2)
				}
			},
			objectSpanMethod({row,column,rowIndex,columnIndex}) {
				if (columnIndex === 0) {
					const _row = this.spanArr[rowIndex];
					const _col = _row > 0 ? 1 : 0;
					return { // [0,0] 表示这一行不显示， [2,1]表示行的合并数
						rowspan: _row,
						colspan: _col
					}
				}
			},
			openDrawer(item){ // 打开抽屉.
				this.dwdialog = true
				this.dwitem = item
				this.activeIndex = 'fee'
				if(this.activeIndex == 'fee'){
					this.addAccount('fee', item)
				}
			},
			handleClose(done) {
			    this.close()
			 //  if (this.dwloading) {
				// return;
			 //  }
				this.newFeeData = []
				this.agentRepayFeeData = []
				this.feeData = []
			    done()
			},
			cancelForm() {
			  this.dwloading = false;
			  this.dwdialog = false;
			  clearTimeout(this.timer);
			},
			  handleSelect(key, keyPath) {
				console.log('handleSelect');
				console.log(key, keyPath);
				this.newFeeData = []
				this.agentRepayFeeData = []
				this.feeData = []
				
				this.activeIndex = key
				this.addAccount(key, this.dwitem)
				
			  }
		},
		watch: {
			total(val, old) {
				this.$nextTick(() => {
					let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
					let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
					let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
					let documentHeight = document.documentElement.clientHeight
					this.height = documentHeight - topNav - 20 - pageBox - 20 - 100
				})
			}
		},
	}
</script>

<style lang="scss" scoped="scoped">
	.formBox {
		width: 80%;
		margin: 0 auto;
	}
	/* 滚动条宽度 */
   ::-webkit-scrollbar {
	   width: 0px;
   }
   table tbody {
	   display: block;
	   overflow-y: auto;
	   -webkit-overflow-scrolling: touch;
	   border-bottom: solid 1px #ddd;
   }	 
   table tbody tr:last-child td{
		border-bottom: none;
   }	 
   table thead tr, table tbody tr, table tfoot tr {
	   box-sizing: border-box;
	   table-layout: fixed;
	   display: table;
	   width: 100%;
   }
   table td{
		word-break:break-all;
   }
   .maxwidth{
	   width: 300px;
   }
   .dataTable{
	   width: 900px;
   }
   .moneyFontSize{
	   font-size: 16px;
   }
   .usdtCss{
	   color: #270082;
	   font-size: xx-small;
   }
   .demo-drawer__content{
	   margin: 30px 80px 30px;
   }
   .el-input {
	   // margin-left: 10px;
       // width: 60%;
    }
   .el-menu-demo{
	   margin-bottom: 40px;
   }
</style>
